import React from 'react';
import './App.css';
import Home from './components/Home';
import About from './components/About';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Header from './components/Header';

function App() {
  return (
   
      <div className="app">
        <Header />
        <Home />
     
      <About />
      <Projects />
      {/* <Skills /> */}
      <Contact />
      </div>
  
  );
}

export default App;
