import React from 'react';
import '../Home.css';
import { Bars3Icon } from '@heroicons/react/24/solid'
import { useState } from 'react';
import skillsImg from '../assets/images/home-page.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faGithub} from '@fortawesome/free-brands-svg-icons';

function Home() {
    const [toggleMenu, setToggleMenu] = useState(false);
    return (
        <section id="home" className="flex px-5 py-20 items-center">
        <div className="flex font-heroine-font text-black m-0">
          <h1 className='text-6xl ml-20 mt-20'>Hey,</h1>
          <h1 className='text-6xl ml-24 mt-36'>I'm Vidyalakshmi S</h1>
          <p className='text-4xl ml-10 mt-52'>Full-Stack Web Developer based in India</p>
              
              <p className='text-3xl ml-20 mt-64'>
                   
                {/* <a href="https://www.linkedin.com/in/vidyalakshmis-418821291" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faLinkedin} className="text-4xl" />
                </a> */}
                <a href="https://github.com/vidyas-myprojects" target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faGithub} className="text-4xl ml-4" />
                </a>
              </p>
    
        </div>
       
        {/* <img className='w-1/3' src={skillsImg}></img> */}
      </section>
      
      
    );
}

export default Home;
