import React from 'react';
import '../About.css';
import aboutMeImg from '../assets/images/about-me.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

function About() {
  return (
    <section id="about" className="about-section flex px-5 py-32 ml-1 mr-1 alternate-section">
   
      {/* <img className='w-1/3 flex flex-col' src={aboutMeImg}></img> */}
        <div className="about-text w-1/2 m-11 flex flex-col mt-0">
          <h2>About Myself</h2>
          <p>
            Hello! I’m a passionate Full Stack Developer with over a decade of experience in building scalable and efficient web applications. I thrive on solving complex challenges and crafting seamless user experiences by merging creative design with cutting-edge technology.
            </p>
         
          <p>
            I specialize in developing end-to-end solutions, from creating intuitive front-end interfaces with React and Angular to architecting robust back-end systems with PHP, Laravel, Codeignier, Python, Node.js, and MySQL. My journey has allowed me to work across various industries, designing and implementing applications ranging from e-commerce platforms to real-time communication tools and dynamic rule engines.
            </p>
            
            <p>
            I’m continuously learning and adapting to new technologies, currently focusing on enhancing my skills in modern frameworks and cloud infrastructure. When I’m not coding, you can find me exploring the world of mandala art, or planning my next big idea.
            </p>
          
            <p>
            Let’s create something amazing together!
          </p>
        </div>
        <div className="about-text flex flex-wrap w-1/2">
          <h2>Skills</h2>
          
          <div className='skills-column w-1/3'>
            <ul className="skills-list mt-20">
              <li><strong>Backend Development:</strong>
                <ul>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Core PHP</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> CodeIgniter</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Laravel</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Node.js</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Python</li>
                </ul>
              </li>
              <li><strong>Frontend Development:</strong>
                <ul>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Angular</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> React</li>
                </ul>
              </li>
              <li><strong>Databases:</strong>
                <ul>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> MySQL</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> SQL Server</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> MongoDB</li>
                </ul>
              </li>
            </ul>
          </div>
          
          <div className='skills-column w-1/3'>
            <ul className="skills-list mt-20">
              <li><strong>DevOps:</strong>
                <ul>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Docker</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> AWS</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Git</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Terraform</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> CI/CD Pipelines</li>
                </ul>
              </li>
              <li><strong>Tools:</strong>
                <ul>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> VS Code</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Postman</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Slack</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> GitHub</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Jira</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Bitbucket</li>
                  <li><FontAwesomeIcon icon={faCheckCircle} className="icon" /> Teamcity</li>
                </ul>
              </li>
            </ul>
          </div>
        </div>

    </section>
  );
}

export default About;
