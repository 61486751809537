import { Bars3Icon } from '@heroicons/react/24/solid'
import { useState } from 'react';
import '../header.css';
// heroicons for react 
export default function Header() {
    const [toggleMenu, setToggleMenu] = useState(false);
    return <header className="header fixed top-0 left-0 w-full flex justify-between px-5 py-2 bg-primary z-0">
        <a className="font-bold text-black text-4xl font-heroine-logo-font" href="#">Vidyalakshmi S</a>
        <nav className="hidden md:block">
        <ul className="flex text-black font-bold">
        <li><a href="#home">Home</a></li>
          <li><a href="#about">About</a></li>
          <li><a href="#projects">Projects</a></li>
          <li><a href="#contact">Contact</a></li>
        </ul>
        </nav>
       { toggleMenu && <nav className="block md:hidden">
        <ul className="flex flex-col text-black mobile-nav font-bold mt-5">
        <li className='mt-2'><a href="#home">Home</a></li>
          <li className='mt-2'><a href="#about">About</a></li>
          <li className='mt-2'><a href="#projects">Projects</a></li>
          <li className='mt-2'><a href="#contact">Contact</a></li>
        </ul>
        </nav>}
        <button onClick={() => setToggleMenu(!toggleMenu)} className='block md:hidden'>
            <Bars3Icon className='text-black h-5'></Bars3Icon> 
        </button>
    </header>
    
}