import React, { useState, useRef } from 'react';
import '../Contact.css';

import emailjs from 'emailjs-com';
import emailjsForm from '@emailjs/browser';

function Contact() {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const form = useRef();
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const [isSubmitted, setIsSubmitted] = useState(false);

  
  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    // Replace with your own EmailJS user ID, service ID, and template ID
   
   

    emailjsForm.sendForm('service_8yuqqyr', 'template_qlvkfuy', form.current, {
      publicKey: 'Aav8nRFjuUoDaXR18',
    })
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        setIsSubmitted(true);
      })
      .catch((err) => console.error('Failed to send email. Error: ', err));
  };

  return (
    <section id="contact" className="contact-section">
      <h2>Contact Me</h2>
      {isSubmitted ? (
        <p>Your message has been sent successfully!</p>
      ) : (
        <p></p>
       )}
       <form ref={form} onSubmit={handleSubmit}>
        <input type="text" name="name" placeholder="Your Name" value={formData.name} onChange={handleChange} />
        <input type="email" name="email" placeholder="Your Email" value={formData.email} onChange={handleChange} />
        <textarea name="message" placeholder="Your Message" value={formData.message} onChange={handleChange}></textarea>
        <button type="submit">Send Message</button>
      </form>
    </section>
  );
}

export default Contact;
