import React from 'react';
import '../Projects.css';

const projects = [
  { title: 'BugTrack Pro: Two-Way Bug Reporting and Tracking System', tech: 'Core PHP, SQLite', description: 'A real-time bug tracking and reporting tool for seamless communication between visitors and the engineering team, built with Core PHP and SQLite.' },
  { title: 'Restaurant Management System', tech: 'CodeIgniter, MySQL, Jquery, HTML, CSS', description: 'Full-featured restaurant management tool' },
  
    {
      title: 'Hotel Management System',
      tech: 'CodeIgniter, MySQL',
      description: 'A comprehensive system for managing hotel operations, including room bookings, customer check-in/check-out, and billing.'
    },
    {
      title: 'College Management System',
      tech: 'CodeIgniter, MySQL',
      description: 'A web application to manage college operations, including student records, course management, and faculty assignments.'
    },
    {
      title: 'School Management System',
      tech: 'CodeIgniter, MySQL',
      description: 'A system for managing school operations, including student enrollment, attendance tracking, and report generation.'
    },
    {
      title: 'Car Parking Slot API',
      tech: 'Laravel, MySQL',
      description: 'An API for managing car parking slots, providing features like availability checking, slot booking, and occupancy tracking.'
    },
    {
      title: 'Tic Tac Toe',
      tech: 'Laravel, MySQL',
      description: 'A simple multiplayer tic-tac-toe game developed using Laravel framework.'
    },
    {
      title: 'Charity Application Management System',
      tech: 'Laravel, MySQL',
      description: 'A charity application processing system that streamlines application creation, validation, and approval through multiple stages for admin and employee users.'
    },
    {
      title: 'Project & Timesheet Management System',
      tech: 'CodeIgniter, MySQL',
      description: 'A system for managing employee timesheets, tracking project hours, and generating time reports for project managers.'
    },
    {
      title: 'Rock Paper and Scissors Game',
      tech: 'Laravel, MySQL',
      description: 'A web-based game where users can play rock-paper-scissors against the system with real-time score tracking.'
    },
    {
      title: 'Airline Booking System',
      tech: 'Angular, Firebase, MongoDB',
      description: 'A front-end application for booking airline tickets, including flight search, seat selection.'
    },
    {
      title: 'Recipe Creation App',
      tech: 'Angular, MySQL',
      description: 'A web app for users to create, manage, and share recipes with an intuitive user interface.'
    },
    {
      title: 'Secure Vault',
      tech: 'Angular, MongoDB',
      description: 'Application designed to help you log and manage various aspects of your personal and professional life in one convenient place.'
    },
    {
      title: 'OrgMaster',
      tech: 'React, MongoDB',
      description: 'A flexible employee management system that supports dynamic organizational hierarchies, common manager identification, salary management, and budget impact calculations for enhanced team oversight.'
    },
    {
      title: 'To-Do List App',
      tech: 'React, MongoDB',
      description: 'A simple and intuitive to-do list app built with React to help users manage tasks efficiently.'
    },
    { title: 'IAM Website', url: 'https://newiam.co.in/', tech: 'Angular, Core PHP, MySQL', description: 'Single page website for IAM product' },
    { title: 'Portfolio Website', url: 'https://animsrini.com/', tech: 'Angular', description: 'Animator portfolio website' },
];

const prof_projects = [
  { title: 'IAM Website', url: 'https://newiam.co.in/', tech: 'Angular, Core PHP, MySQL', description: 'Single page website for IAM product' },
  { title: 'Portfolio Website', url: 'https://animsrini.com/', tech: 'Angular', description: 'Animator portfolio website' },
];

function Projects() {
  return (
    <section id="projects" className="projects-section ml-10 mr-10">
      <h2>Personal Projects</h2>
      <div className="projects-grid">
        {projects.map((project, index) => (
          <div className="project-card" key={index}>
            <h3>{project.title}</h3>
            <p>{project.description}</p>
            <span>{project.tech}</span>
          </div>
        ))}
      </div>
      <br></br>
      {/* <h2>Professional Projects</h2>
      <div className="projects-grid">
        {prof_projects.map((project, index) => (
          <div className="project-card" key={index}>
            <a href={project.url} target='_blank'><h3>{project.title}</h3></a>
            <p>{project.description}</p>
            <span>{project.tech}</span>
          </div>
        ))}
      </div> */}
    </section>
  );
}

export default Projects;
